import React from "react"
import Layout from "../components/layout"
import { IoMdSad } from "react-icons/io"
import { Helmet } from "react-helmet"

const NotFoundPage = () => (
  <Layout type="hr">
    <Helmet>
      <meta charSet="utf-8" />
      <title>Atolo - Eval | Page not found</title>
    </Helmet>
    <div className="flex flex-col items-center justify-center">
      <IoMdSad size={250} className="opacity-75" />
      <div className="text-7xl ">404!</div>
      <div className="text-md">Oops! Page was not found.</div>
      <div className="text-md opacity-50">The page you are looking for doesn't exist or another error occured</div>
    </div>
  </Layout>
)

export default NotFoundPage
